<template>
  <div>
    <title>SIM INVENTORY ~ REPORTING WAREHOUSE OUTGOING</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Reporting Warehouse Outgoing Lists
        <br />
        <h4>The following is a list of reporting warehouse outgoing</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Reporting</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header">
              <div class="box-body">
                <div class="row">
                  <div class="col-md-2">
                    Start Date &nbsp; :
                  </div>
                  <div class="col-md-3">
                    <input
                      type="date"
                      v-model="mulai"
                      class="form-control"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="col-md-2">
                    End Date &nbsp; :
                  </div>
                  <div class="col-md-3">
                    <input
                      type="date"
                      v-model="selesai"
                      class="form-control"
                      name=""
                      id=""
                    />
                  </div>
                  <div class="col-md-2">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isSearch">
        <div class="row">
          <div class="col-md-12">
            <div class="box box-danger">
              <div class="box-header">
                <h3 class="box-title">Reporting Warehouse Outgoing Lists</h3>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table id="mydata" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Outgoing Number</th>
                        <th>Order Number</th>
                        <th>Date Out</th>
                        <th>Out Type</th>
                        <th>Request Number</th>
                        <th>Product Number</th>
                        <th>Product Name</th>
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="classdata in list_paged"
                        :key="classdata.outgoing_number"
                      >
                        <!-- <td>{{ classdata.id }}</td> -->
                        <td>{{ classdata.outgoing_number }}</td>
                        <td>{{ classdata.order_number }}</td>
                        <td>{{ classdata.date_out }}</td>
                        <td>{{ classdata.jenis_out }}</td>
                        <td>{{ classdata.request_number }}</td>
                        <td>{{ classdata.nomor_produk }}</td>
                        <td>{{ classdata.nama_produk }}</td>
                        <td>{{ classdata.kode_barang }}</td>
                        <td>{{ classdata.nama_barang }}</td>
                        <td>{{ classdata.qty }} &nbsp; {{ classdata.unit }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      list_paged: [],
      mulai: "",
      selesai: "",
      isSearch: false
    };
  },
  created(){
    this.getnow()
  },
  methods: {

    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.mulai = tahun + "-" + month + "-" + tgl;
      this.selesai = tahun + "-" + month + "-" + tgl;
    },
    searchdata() {
      this.loading = true;
      this.isSearch = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "outgoing_laporan/get_v_lap_outgoing_whby_periode_tanggal?tanggal_awal=" +
        this.mulai +
        "&tanggal_akhir=" +
        this.selesai;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    }
  }
};
</script>
